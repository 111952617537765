@import '../../style/themes/index';
@import '../../style/mixins/index';

@tag-prefix-cls: ~'@{ant-prefix}-tag';

.@{tag-prefix-cls} {
  .reset-component;

  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: @tag-font-size;
  line-height: @tag-line-height;
  white-space: nowrap;
  background: @tag-default-bg;
  border: @border-width-base @border-style-base @border-color-base;
  border-radius: @border-radius-base;
  cursor: default;
  opacity: 1;
  transition: all 0.3s @ease-in-out-circ;

  &:hover {
    opacity: 0.85;
  }

  &,
  a,
  a:hover {
    color: @tag-default-color;
  }

  > a:first-child:last-child {
    display: inline-block;
    margin: 0 -8px;
    padding: 0 8px;
  }

  .@{iconfont-css-prefix}-close {
    .iconfont-size-under-12px(10px);

    margin-left: 3px;
    color: @text-color-secondary;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s @ease-in-out-circ;

    &:hover {
      color: @heading-color;
    }
  }

  &-has-color {
    border-color: transparent;
    &,
    a,
    a:hover,
    .@{iconfont-css-prefix}-close,
    .@{iconfont-css-prefix}-close:hover {
      color: @text-color-inverse;
    }
  }

  &-checkable {
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
    &:not(&-checked):hover {
      color: @primary-color;
    }
    &:active,
    &-checked {
      color: @text-color-inverse;
    }
    &-checked {
      background-color: @primary-6;
    }
    &:active {
      background-color: @primary-7;
    }
  }

  &-hidden {
    display: none;
  }

  // mixin to iterate over colors and create CSS class for each one
  .make-color-classes(@i: length(@preset-colors)) when (@i > 0) {
    .make-color-classes(@i - 1);
    @color: extract(@preset-colors, @i);
    @lightColor: '@{color}-1';
    @lightBorderColor: '@{color}-3';
    @darkColor: '@{color}-6';
    &-@{color} {
      color: @@darkColor;
      background: @@lightColor;
      border-color: @@lightBorderColor;
    }
    &-@{color}-inverse {
      color: @text-color-inverse;
      background: @@darkColor;
      border-color: @@darkColor;
    }
  }

  .make-status-color-classes(@color, @status) {
    @lightColor: '@{color}-1';
    @lightBorderColor: '@{color}-3';
    @darkColor: '@{color}-6';
    &-@{status} {
      color: @@darkColor;
      background: @@lightColor;
      border-color: @@lightBorderColor;
    }
  }

  .make-color-classes();

  .make-status-color-classes('green', success);
  .make-status-color-classes('blue', processing);
  .make-status-color-classes('red', error);
  .make-status-color-classes('orange', warning);

  // To ensure that a space will be placed between character and `Icon`.
  > .@{iconfont-css-prefix} + span,
  > span + .@{iconfont-css-prefix} {
    margin-left: 7px;
  }
}

@import './rtl';

@primary-color: #b20008;